import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import IntroContent from "./IntroContent";
import Hamburger from "./Hamburger";
import logo from "./SOEL-LOGO2.png";
import OurServices from "./OurServices";
import GetStarted from "./GetStarted";
import "./Home.css";
import Transition from "./Transition";

const Home = () => {
  useEffect(() => {
    // Create a new script element
    const script = document.createElement("script");

    // Set the script type
    script.type = "text/javascript";

    // Set the script content (your provided script)
    script.innerHTML = `
      window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'f322439d3a60b959aab5ea0ff6b1484b697adf97', { region: 'eu' });
    `;

    // Append the script element to the head
    document.head.appendChild(script);

    // Clean up by removing the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const introRef = useRef(null);
  const servicesRef = useRef(null);
  const getStartedRef = useRef(null);

  const [activeSection, setActiveSection] = useState(introRef);
  const [selected, setSelected] = useState(0);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [isInSectionView, setIsInSectionView] = useState(Array(3).fill(false));
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [isTransitioning, setTransitioning] = useState(false);

  const navItems = [
    { id: "intro", name: "Introduction" },
    { id: "services", name: "Our Services" },
    { id: "start", name: "Get Started" },
  ];

  const sectionRefs = [introRef, servicesRef, getStartedRef];

  const handleClick = (index) => {
    setIsUserScrolling(true);
    setSelected(index);
    setActiveSection(sectionRefs[index]);
    scrollToSection(index);
  };

  const scrollToSection = (index) => {
    const selectedSection = sectionRefs[index]?.current;
    if (selectedSection) {
      // You can add your scrolling logic here
    }
  };

  useEffect(() => {
    setTransitioning(true);
  }, [selected]);

  useEffect(() => {
    const handleTTNSEvent = () => {
      console.log("Transition to next section");
      setActiveSection(servicesRef); // Set activeSection to servicesRef when TTNS event is triggered
    };

    window.addEventListener("TTNS", handleTTNSEvent);

    return () => {
      window.removeEventListener("TTNS", handleTTNSEvent);
    };
  }, []);

  useEffect(() => {
    // Update the activeSectionIndex when activeSection changes
    const newIndex = sectionRefs.indexOf(activeSection);
    if (newIndex !== -1) {
      setActiveSectionIndex(newIndex);
    }
  }, [activeSection]);

  // Add a scroll event listener to track the active section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      sectionRefs.forEach((ref, index) => {
        if (ref.current) {
          const { offsetTop, offsetHeight } = ref.current;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveSection(sectionRefs[index]);
          }
        }
      });
    };

    const handleGetStartedPressed = () => {
      setActiveSection(getStartedRef);
      setSelected(2);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("getStartedPressed", handleGetStartedPressed);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("getStartedPressed", handleGetStartedPressed);
    };
  }, []);

  useEffect(() => {
    // Create a function to handle the custom event
    const handleUserScrolledUp = () => {
      console.log("User scrolled up!");
      // Set the active section to introRef
      setActiveSection(introRef);
      // You can perform any other actions you need here when the event is triggered.
    };

    // Add an event listener to listen for the custom "userScrolledUp" event
    window.addEventListener("userScrolledUp", handleUserScrolledUp);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("userScrolledUp", handleUserScrolledUp);
    };
  }, []);

  useEffect(() => {
    // Create a function to handle the custom event "userScrolledDown"
    const handleUserScrolledDown = () => {
      console.log("User scrolled down!");
      // Set the active section to introRef
      setActiveSection(getStartedRef);
    };

    // Add an event listener to listen for the custom "userScrolledDown" event
    window.addEventListener("userScrolledDown", handleUserScrolledDown);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("userScrolledDown", handleUserScrolledDown);
    };
  }, []);

  useEffect(() => {
    const handleUserScrolledUp2 = () => {
      console.log("User scrolled up in GetStarted.js");
      setActiveSection(servicesRef);
      // You can perform any actions you need here when the event is triggered.
    };

    window.addEventListener("userscrolledup2", handleUserScrolledUp2);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("userscrolledup2", handleUserScrolledUp2);
    };
  }, []);

  return (
    <div className="container">
      {/* Render your navigation items based on the activeSectionIndex */}
      <Hamburger />
      <img src={logo} alt="SOEL Logo" className="logo" />
      <div className="nav-container">
        <Hamburger />
        <img src={logo} alt="SOEL Logo" className="logo" />
        <div className="nav-container">
          {navItems.map((item, index) => (
            <div
              key={item.id}
              className={`nav-item ${
                activeSectionIndex === index ? "active" : ""
              }`}
              onClick={() => handleClick(index)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <Transition
        isTransitioning={isTransitioning}
        setTransitioning={setTransitioning}
      />
      {activeSection === introRef && (
        <div
          className={`section intro ${
            activeSection === introRef ? "active" : ""
          }`}
          ref={introRef}
        >
          <IntroContent />
        </div>
      )}
      {activeSection === servicesRef && (
        <div
          className={`section our-services ${
            activeSection === servicesRef ? "active" : ""
          }`}
          ref={servicesRef}
        >
          <OurServices />
        </div>
      )}
      {activeSection === getStartedRef && (
        <div
          className={`section get-started ${
            activeSection === getStartedRef ? "active" : ""
          }`}
          ref={getStartedRef}
        >
          <GetStarted />
        </div>
      )}
    </div>
  );
};

export default Home;
