import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import "./MobileGetStarted.css";

import axios from "axios"; // Change the path accordingly

import { TextPlugin } from "gsap/TextPlugin";

import MobileIntroVideo from "./MobileBGVid-03.mp4"; // Import the video file

gsap.registerPlugin(TextPlugin);

const consultingDescriptions = {
  "El Raise":
    "Unlock your startup's true potential with our expert Fundraising services. From venture capital to crowdfunding and token sales, our EL Raise package offers end-to-end support. Don't miss the opportunity; contact us now to kickstart your fundraising journey.",
  "El Deck":
    "We create and design your pitch deck using your existing material, within a 7 business day timeframe. This includes up to 20 slides with 2 rounds of revisions on the design.",
  "El Strategy":
    "From Problem solution Fit, to building your Startup's defensibility: we craft a strategy to help your startup scale smart",
};

const techDescriptions = {
  "El Tech Infrastructure":
    "Add the 'wow' factor to your product with our 'Build me a Feature' service. From back-end databases to interactive front-end UIs, we create features that elevate user engagement. Ready to make your software unforgettable? Contact us now.",
  "El UX/UI":
    "We will strategize, plan, and build your software's full UX/UI journey. Deliverables are figma files.",
  "El Web3":
    "Disrupt the market with our Web3 services. From blockchain to smart contracts and DeFi, our EL Web3-build offers comprehensive consulting and development expertise. Intrigued by the decentralized revolution? Contact us now to innovate.",
};

const MobileGetStarted = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    option: "",
    message: "",
  });

  const [selectedConsultingOptions, setSelectedConsultingOptions] = useState(
    [],
  );
  const [selectedTechOptions, setSelectedTechOptions] = useState([]);

  const [selectedConsultingDescription, setSelectedConsultingDescription] =
    useState("");
  const [selectedTechDescription, setSelectedTechDescription] = useState("");

  const [formError, setFormError] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [showTechInfo, setShowTechInfo] = useState(false);
  const [showConsultingInfo, setShowConsultingInfo] = useState(false);

  const sentenceRef = useRef(null);

  const [optionsSelected, setOptionsSelected] = useState(false);

  // Create a ref for the video element
  const videoRef = useRef(null);
  // Add a state variable to track video play/pause
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // Function to handle video play
  const handleVideoPlay = () => {
    const video = videoRef.current;

    if (video && video.paused) {
      video
        .play()
        .then(() => {
          // Video playback started successfully
          setIsVideoPlaying(true);
        })
        .catch((error) => {
          // Handle any errors, e.g., autoplay is not allowed
          console.error("Video playback error:", error);
        });
    }
  };

  // Add an event listener to the document to detect touch or click
  useEffect(() => {
    const handleTouchOrClick = () => {
      if (!isVideoPlaying) {
        handleVideoPlay();
      }
    };

    document.addEventListener("touchstart", handleTouchOrClick);
    document.addEventListener("click", handleTouchOrClick);

    return () => {
      document.removeEventListener("touchstart", handleTouchOrClick);
      document.removeEventListener("click", handleTouchOrClick);
    };
  }, [isVideoPlaying]);

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormError(false);

    if (value === "tech" && !showTechInfo) {
      setShowTechInfo(true);
      setShowConsultingInfo(false);
    } else if (value === "consulting" && !showConsultingInfo) {
      setShowTechInfo(false);
      setShowConsultingInfo(true);

      // Update consulting description
      const consultingDescription =
        selectedConsultingOptions.length > 0
          ? consultingDescriptions[selectedConsultingOptions[0]]
          : "";
      setSelectedConsultingDescription(consultingDescription);
    } else if (value === "consulting" && showConsultingInfo) {
      setShowTechInfo(false);
    } else if (value === "tech" && showTechInfo) {
      setShowConsultingInfo(false);
    } else {
      setShowTechInfo(false);
      setShowConsultingInfo(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setFormError("Please enter a valid email address.");
      return;
    }

    setIsSending(true);

    const dataToSend = {
      ...formData,
      selectedConsultingOptions,
      selectedTechOptions,
    };

    const proxyUrl = "https://soel-website.nassifchedrawi1.repl.co";
    const apiUrl = `${proxyUrl}/submitForm`;

    axios
      .post(apiUrl, dataToSend)
      .then((response) => {
        console.log("Form data sent successfully:", response.data);
        setIsSending(false);
        setIsSent(true);
        setSubmissionStatus("success");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setIsSending(false);
        setSubmissionStatus("error");
      });
  };

  const handleConsultingOptionClick = (option) => {
    setSelectedConsultingOptions((prevOptions) => {
      const newOptions = prevOptions.includes(option)
        ? prevOptions.filter((prevOption) => prevOption !== option)
        : [...prevOptions, option];

      // Update consulting descriptions based on selected options
      const descriptions = newOptions.map((opt) => consultingDescriptions[opt]);
      setSelectedConsultingDescription(descriptions.join("\n"));

      return newOptions;
    });
    setOptionsSelected(true);
  };

  const handleTechOptionClick = (option) => {
    setSelectedTechOptions((prevOptions) => {
      const newOptions = prevOptions.includes(option)
        ? prevOptions.filter((prevOption) => prevOption !== option)
        : [...prevOptions, option];

      // Update tech descriptions based on selected options
      const descriptions = newOptions.map((opt) => techDescriptions[opt]);
      setSelectedTechDescription(descriptions.join("\n"));

      return newOptions;
    });
    setOptionsSelected(true);
  };

  //Dispatch scroll event to parent to change section

  // Function to handle scroll up event
  const handleScrollUp = () => {
    console.log("Scroll up in MobileGetStarted");
    // Dispatch an event to the parent component with a consistent event name
    document.dispatchEvent(new Event("swipeUpInMobileGetStarted"));
  };

  // Add event listeners to detect scroll, key up, and touch scroll up
  useEffect(() => {
    const handleScroll = (e) => {
      if (e.deltaY < 0) {
        // Scroll up detected
        handleScrollUp();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "ArrowUp") {
        // Up arrow key pressed
        handleScrollUp();
      }
    };

    const handleTouchScroll = (e) => {
      // Check if any input or textarea element within the form has focus
      const isFormFocused =
        document.activeElement &&
        (document.activeElement.tagName === "INPUT" ||
          document.activeElement.tagName === "TEXTAREA");

      // Check if the form is not focused and there's only one touch
      if (!isFormFocused && e.touches.length === 1) {
        const touchStartY = e.touches[0].clientY;
        let touchEndY;

        const handleTouchMove = (moveEvent) => {
          touchEndY = moveEvent.touches[0].clientY;
        };

        const handleTouchEnd = () => {
          if (touchStartY - touchEndY < 0) {
            // Touch scroll up detected
            handleScrollUp();
          }

          // Remove touch event listeners
          document.removeEventListener("touchmove", handleTouchMove);
          document.removeEventListener("touchend", handleTouchEnd);
        };

        // Add touch event listeners
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleTouchEnd);
      }
    };

    // Add event listeners
    document.addEventListener("wheel", handleScroll);
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("touchstart", handleTouchScroll);

    return () => {
      // Remove event listeners on component unmount
      document.removeEventListener("wheel", handleScroll);
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("touchstart", handleTouchScroll);
    };
  }, []);

  return (
    <div className="mobile-full-screen-container-Mob">
      <div className="right-side-Mob">
        <video
          id="backgroundVideo"
          autoPlay
          loop
          muted
          className="video-background-Mob"
          ref={videoRef}
          playsInline // Add playsInline attribute
        >
          <source src={MobileIntroVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="form-container-gs-Mob">
          <h2>Get Started</h2>
          {submissionStatus === "success" ? (
            <div className="submission-confirmation-gs-Mob">
              Message sent successfully!
            </div>
          ) : (
            formError && <div className="error-message-gs-Mob">{formError}</div>
          )}
          {submissionStatus === "success" ? (
            <div className="submitted-info-gs-Mob">
              <h3>Submitted Information:</h3>
              <p>Name: {formData.name}</p>
              <p>Email: {formData.email}</p>
              <p>Option: {formData.option}</p>
              {formData.option === "consulting" && (
                <p>
                  Consulting Options: {selectedConsultingOptions.join(", ")}
                </p>
              )}
              {formData.option === "tech" && (
                <p>Technology Options: {selectedTechOptions.join(", ")}</p>
              )}
              <p>Message: {formData.message}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} noValidate>
              {/* Form inputs */}
              <div className="input-field-gs-Mob">
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  onChange={handleInputChange}
                  placeholder="Enter your name"
                />
              </div>
              <div className="input-field-gs-Mob">
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="input-field-gs-Mob">
                <select
                  id="option"
                  name="option"
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Select a service line</option>
                  <option value="consulting">Consulting</option>
                  <option value="tech">Tech</option>
                </select>
              </div>
              {formData.option === "consulting" && (
                <div className="options-container-gs-Mob">
                  <p className="options-label-gs-Mob">Consulting Options:</p>
                  <div className="options-boxes-gs-Mob">
                    <div
                      className={`option-box-gs-Mob ${
                        selectedConsultingOptions.includes("El Fundraise") &&
                        "selected-gs-Mob"
                      }`}
                      onClick={() =>
                        handleConsultingOptionClick("El Fundraise")
                      }
                    >
                      El Raise
                    </div>
                    <div
                      className={`option-box-gs-Mob ${
                        selectedConsultingOptions.includes("El Deck") &&
                        "selected-gs-Mob"
                      }`}
                      onClick={() => handleConsultingOptionClick("El Deck")}
                    >
                      El Deck
                    </div>
                    <div
                      className={`option-box-gs-Mob ${
                        selectedConsultingOptions.includes("El Strategy") &&
                        "selected-gs-Mob"
                      }`}
                      onClick={() => handleConsultingOptionClick("El Strategy")}
                    >
                      El Strategy
                    </div>
                  </div>
                </div>
              )}

              {formData.option === "tech" && (
                <div className="options-container-gs-Mob">
                  <p className="options-label-gs-Mob">Technology Options:</p>
                  <div className="options-boxes-gs-Mob">
                    <div
                      className={`option-box-gs-Mob ${
                        selectedTechOptions.includes(
                          "El Tech Infrastructure",
                        ) && "selected-gs-Mob"
                      }`}
                      onClick={() =>
                        handleTechOptionClick("El Tech Infrastructure")
                      }
                    >
                      El Tech Infrastructure
                    </div>
                    <div
                      className={`option-box-gs-Mob ${
                        selectedTechOptions.includes("El UX/UI") &&
                        "selected-gs-Mob"
                      }`}
                      onClick={() => handleTechOptionClick("El UX/UI")}
                    >
                      El UX/UI
                    </div>
                    <div
                      className={`option-box-gs-Mob ${
                        selectedTechOptions.includes("El Web3") &&
                        "selected-gs-Mob"
                      }`}
                      onClick={() => handleTechOptionClick("El Web3")}
                    >
                      El Web3
                    </div>
                  </div>
                </div>
              )}
              <div className="input-field-gs-Mob">
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInputChange}
                  placeholder="Your message (optional)"
                />
              </div>

              <button
                type="submit"
                className={`send-button-Mob ${isSending ? "sending" : ""}`}
                disabled={isSending || isSent}
              >
                {isSending ? "Sending..." : isSent ? "Sent ✔" : "Send Message"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileGetStarted;
