import React, { useEffect, useState, useRef } from "react"; // <-- Added useRef import
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import "./OurServices.css";
import ConsultingDetails from "./ConsultingDetails"; // Replace with the actual path
import TechnologyDetails from "./TechnologyDetails"; // Import the TechnologyDetails component

gsap.registerPlugin(TextPlugin);

const OurServices = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canScroll, setCanScroll] = useState(true);
  const [hoveredSection, setHoveredSection] = useState("");
  const [descriptionVisible, setDescriptionVisible] = useState(false);

  const sentence = "So-EL POWERS FOUNDERS THROUGH TWO MAIN SERVICES";
  const sentenceRef = useRef(null); // <-- Added the ref for the sentence

  const [showConsultingButton, setShowConsultingButton] = useState(false);
  const [showTechnologyButton, setShowTechnologyButton] = useState(false);
  const [showConsultingDetails, setShowConsultingDetails] = useState(false);
  const [showTechnologyDetails, setShowTechnologyDetails] = useState(false); // Add state for technology details
  const [switchValue, setSwitchValue] = useState(0);

  const handleGetStarted = () => {
    console.log("Get Started button pressed");
    setSwitchValue(1);

    // Dispatch a custom event to notify Home
    const event = new Event("getStartedPressed");
    window.dispatchEvent(event);
  };

  const typingEffect = () => {
    gsap.set(sentenceRef.current, { opacity: 0, text: "" }); // Start with an invisible empty string

    gsap.to(sentenceRef.current, {
      duration: sentence.length / 10, // This will type at roughly 10 chars/sec
      text: sentence,
      opacity: 1,
      ease: "power1.out",
      delay: 0.5,
    });
  };

  useEffect(() => {
    typingEffect();
  }, []);

  //scroll logic
  const handleScroll = (e) => {
    if (canScroll) {
      const scrollDirection = e.deltaY > 0 ? 1 : -1;

      if (currentIndex === 0 && scrollDirection === 1) {
        fadeOutSentence();
      } else if (currentIndex === 0 && scrollDirection === -1) {
        // Dispatch a custom event to notify Home.js
        const event = new Event("userScrolledUp");
        window.dispatchEvent(event);
        // Log the event to the console
        console.log("User scrolled up!");
      } else if (currentIndex === 1 && scrollDirection === -1) {
        fadeInSentence();
      } else if (currentIndex === 1 && scrollDirection === 1) {
        // Dispatch a custom event to notify Home.js
        const event = new Event("userScrolledDown");
        window.dispatchEvent(event);
        // Log the event to the console
        console.log("User scrolled down!");
      }
    }
  };
  //scroll logic
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (currentIndex === 0 && e.key === "ArrowDown") {
        fadeOutSentence();
      } else if (currentIndex === 0 && e.key === "ArrowUp") {
        // Dispatch a custom event to notify Home.js
        const event = new Event("userScrolledUp");
        window.dispatchEvent(event);
        // Log the event to the console
        console.log("User scrolled up!");
      } else if (currentIndex === 1 && e.key === "ArrowUp") {
        fadeInSentence();
      } else if (currentIndex === 1 && e.key === "ArrowDown") {
        // Dispatch a custom event to notify Home.js
        const event = new Event("userScrolledDown");
        window.dispatchEvent(event);
        // Log the event to the console
        console.log("User scrolled down!");
      }
    };

    const handleTouchStart = (e) => {
      const touchStartY = e.touches[0].clientY;

      const handleTouchMove = (moveEvent) => {
        const touchEndY = moveEvent.changedTouches[0].clientY;
        const scrollDirection = touchEndY > touchStartY ? 1 : -1;

        if (scrollDirection === 1) {
          fadeOutSentence();
        } else if (currentIndex === 0 && scrollDirection === -1) {
          // Dispatch a custom event to notify Home.js
          const event = new Event("userScrolledUp");
          window.dispatchEvent(event);
          // Log the event to the console
          console.log("User scrolled up!");
        } else if (currentIndex === 1 && scrollDirection === -1) {
          fadeInSentence();
        } else if (currentIndex === 1 && scrollDirection === 1) {
          // Dispatch a custom event to notify Home.js
          const event = new Event("userScrolledDown");
          window.dispatchEvent(event);
          // Log the event to the console
          console.log("User scrolled down!");
        }

        // Remove the touch move event listener
        window.removeEventListener("touchmove", handleTouchMove);
      };

      window.addEventListener("touchmove", handleTouchMove);
    };

    window.addEventListener("wheel", handleScroll);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, [currentIndex, canScroll]);

  useEffect(() => {
    gsap.set("#consulting, #technology", { opacity: 0, y: "100%" });
  }, []);

  const fadeOutSentence = () => {
    setCanScroll(false);
    const timeline = gsap.timeline({
      onComplete: () => {
        setCurrentIndex(1);
        setCanScroll(true);
      },
    });

    timeline.to(sentenceRef.current, {
      // <-- Updated this line
      duration: 1,
      y: "-100%",
      ease: "power4.inOut",
    });

    timeline.fromTo(
      "#consulting, #technology",
      { y: "100%", opacity: 0 }, // start from below and hidden
      { duration: 0.025, y: "0%", opacity: 1, ease: "power4.inOut" }, // moves them into view and make them visible
    );
  };

  const fadeInSentence = () => {
    setCanScroll(false);
    const timeline = gsap.timeline({
      onComplete: () => {
        setCurrentIndex(0);
        setCanScroll(true);
      },
    });

    timeline.to("#consulting, #technology", {
      duration: 1,
      y: "100%",
      opacity: 0, // fade them out
      ease: "power4.inOut",
    });

    timeline.fromTo(
      sentenceRef.current, // <-- Updated this line
      { y: "-100%" },
      { duration: 1, y: "0%", ease: "power4.inOut" },
    );
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    return () => window.removeEventListener("wheel", handleScroll);
  }, [currentIndex, canScroll]);

  return (
    <div className="services-container">
      <div ref={sentenceRef} className="service main-section"></div>

      <div
        id="consulting"
        className={`sub-section left`}
        onMouseEnter={() => {
          setHoveredSection("consulting");
          setDescriptionVisible(true);
          setShowConsultingButton(true); // Show the "Learn more" button
        }}
        onMouseLeave={() => {
          setDescriptionVisible(false);
          setShowConsultingButton(false); // Hide the "Learn more" button
        }}
      >
        <div className="title-box">
          <h1>Consulting</h1>
          <p
            className={`description ${
              descriptionVisible && hoveredSection === "consulting"
                ? "show"
                : ""
            }`}
          >
            Our mission transcends mere consulting; we equip you with knowledge
            and skills for enduring business success.
          </p>
          {showConsultingButton && (
            <button
              className="learn-more-button"
              onClick={() => setShowConsultingDetails(true)}
            >
              Learn more
            </button>
          )}
        </div>
      </div>

      <div
        id="technology"
        className={`sub-section right`}
        onMouseEnter={() => {
          setHoveredSection("technology");
          setDescriptionVisible(true);
          setShowTechnologyButton(true); // Show the "Learn more" button
        }}
        onMouseLeave={() => {
          setDescriptionVisible(false);
          setShowTechnologyButton(false); // Hide the "Learn more" button
        }}
      >
        <div className="title-box">
          <h1>Technology</h1>
          <p
            className={`description ${
              descriptionVisible && hoveredSection === "technology"
                ? "show"
                : ""
            }`}
          >
            Our focus extends beyond crafting technology solutions; we aim to
            empower your team for sustainable growth.
          </p>
          {showTechnologyButton && (
            <button
              className="learn-more-button"
              onClick={() => setShowTechnologyDetails(true)} // Ensure this is correctly setting showTechnologyDetails to true
            >
              Learn more
            </button>
          )}
        </div>
      </div>

      {/* Render the ConsultingDetails component when showConsultingDetails is true */}
      {showConsultingDetails && (
        <ConsultingDetails
          onClose={() => setShowConsultingDetails(false)}
          onGetStarted={handleGetStarted}
        />
      )}

      {/* Render the TechnologyDetails component when showTechnologyDetails is true */}
      {showTechnologyDetails && (
        <TechnologyDetails
          onClose={() => setShowTechnologyDetails(false)}
          onGetStarted={handleGetStarted}
        />
      )}

      <div className="mouse-field-container">
        <div className="mouse-field">
          <div className="mouse"></div>
        </div>
        <div className="scroll-down-text">SCROLL DOWN</div>
      </div>
    </div>
  );
};

export default OurServices;
