// MobileTechD.js
import React, { useState, useRef, useEffect } from "react";
import "./MobileTechD.css";
import gsap from "gsap";
import {
  FaPencilAlt, // Placeholder for EL UX/UI
  FaLaptop, // Placeholder for EL Tech Infrastructure
  FaChartLine, // Placeholder for EL Tech Strategy
  FaCogs, // Placeholder for EL Tech Continuity
  FaBox, // Placeholder for EL Tech Package
  FaCube, // Placeholder for EL Web3-build
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
//import { useSwipeable } from 'react-swipeable';

const MobileTechD = ({ onGetStarted }) => {
  const progressRef = useRef(null);

  const technologyServices = [
    {
      title: "EL UX/UI",
      description:
        "We will strategize, plan, and build your software's full UX/UI journey. Deliverables are figma files.",
      icon: <FaPencilAlt />,
    },
    {
      title: "EL Tech Infrastructure",
      description:
        "We help startups build a robust tech foundation, from backend databases to friendly UIs.",
      icon: <FaLaptop />,
    },
    {
      title: "EL Tech Strategy",
      description:
        "Our team outlines a comprehensive tech roadmap that aligns with your business goals.",
      icon: <FaChartLine />,
    },
    {
      title: "EL Tech Continuity",
      description:
        "We provide ongoing tech support, including audits, upgrades, and maintenance, ensuring your tech aspects continue to evolve with market trends.",
      icon: <FaCogs />,
    },
    {
      title: "EL Tech Package",
      description:
        "This all-in-one solution combines our tech services, providing holistic tech support to startups throughout their journey.",
      icon: <FaBox />, // Use FaBox instead of FaPackage
    },
    {
      title: "EL Web3-build",
      description:
        "Leverage blockchain, smart contracts, decentralized applications to build solutions in a decentralized ecosystem.",
      icon: <FaCube />,
    },
  ];

  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

  const animateServiceChange = () => {
    const timeline = gsap.timeline();
    timeline.to(".service-title svg", { y: -10, duration: 0.2 });
    timeline.from(
      ".service-title h2",
      { x: -20, opacity: 0, duration: 0.4 },
      "-=0.2",
    );
  };

  const changeService = (newIndex) => {
    gsap.to(".service-content", { opacity: 0, duration: 0.3 });
    animateServiceChange();
    animateBackgroundColorChange();
    setTimeout(() => {
      setCurrentServiceIndex(newIndex);
      gsap.to(".service-content", { opacity: 1, duration: 0.3 });
    }, 300);
  };

  const nextService = () => {
    changeService((prevIndex) => (prevIndex + 1) % technologyServices.length);
  };

  const prevService = () => {
    changeService(
      (prevIndex) =>
        (prevIndex - 1 + technologyServices.length) % technologyServices.length,
    );
  };

  const animateBackgroundColorChange = () => {
    const colors = [
      "#3f49e8", // Blue
      "#f053bb", // Pink
      "#2ecc71", // Green
      "#e67e22", // Orange
      "#9b59b6", // Purple
      "#3498db", // Light Blue
    ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    gsap.to(".technology-details-popup", {
      backgroundColor: randomColor,
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  const updateProgress = () => {
    const progress =
      ((currentServiceIndex + 1) / technologyServices.length) * 100;
    gsap.to(".progress-bar-fill", {
      width: `${progress}%`,
      duration: 0.5,
      ease: "power3.out",
    });
  };

  useEffect(updateProgress, [currentServiceIndex]);

  // Step 2: Call the onGetStarted function when the button is pressed
  const handleGetStarted = () => {
    onGetStarted(); // Call the provided onGetStarted function
  };

  return (
    <div className="technology-details-popup-mob">
      <div className="service-content-mob">
        <div className="service-title-container-mob">
          {technologyServices[currentServiceIndex].icon}
          <h2 className="service-title-mob">
            {technologyServices[currentServiceIndex].title}
          </h2>
        </div>
        <p className="service-description-mob">
          {technologyServices[currentServiceIndex].description}
        </p>
      </div>
      <div className="carousel-nav-mob">
        <button className="carousel-button-mob" onClick={prevService}>
          <FaChevronLeft />
        </button>

        <button className="carousel-button-mob" onClick={nextService}>
          <FaChevronRight />
        </button>
      </div>
      <div className="carousel-dots-mob">
        {technologyServices.map((_, index) => (
          <button
            key={index}
            className={`carousel-dot-mob ${
              index === currentServiceIndex ? "active-mob" : ""
            }`}
            onClick={() => changeService(index)}
          />
        ))}
      </div>
      {/* Step 3: Call the handleGetStarted function when the button is pressed */}
      <button className="get-started-button-mob" onClick={handleGetStarted}>
        Get Started
      </button>
    </div>
  );
};

export default MobileTechD;
