import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { Power3 } from "gsap";
import "./GetStarted.css";

import axios from "axios"; // Change the path accordingly

import { TextPlugin } from "gsap/TextPlugin";
import "./GetStarted.css";
import videoBackground from "./Media/BGVid-03.mp4"; // Import the video file

gsap.registerPlugin(TextPlugin);

const consultingDescriptions = {
  "El Raise":
    "Unlock your startup's true potential with our expert Fundraising services. From venture capital to crowdfunding and token sales, our EL Raise package offers end-to-end support. Don't miss the opportunity; contact us now to kickstart your fundraising journey.",
  "El Deck":
    "We create and design your pitch deck using your existing material, within a 7 business day timeframe. This includes up to 20 slides with 2 rounds of revisions on the design.",
  "El Strategy":
    "From Problem solution Fit, to building your Startup's defensibility: we craft a strategy to help your startup scale smart",
};

const techDescriptions = {
  "El Tech Infrastructure":
    "Add the 'wow' factor to your product with our 'Build me a Feature' service. From back-end databases to interactive front-end UIs, we create features that elevate user engagement. Ready to make your software unforgettable? Contact us now.",
  "El UX/UI":
    "We will strategize, plan, and build your software's full UX/UI journey. Deliverables are figma files.",
  "El Web3":
    "Disrupt the market with our Web3 services. From blockchain to smart contracts and DeFi, our EL Web3-build offers comprehensive consulting and development expertise. Intrigued by the decentralized revolution? Contact us now to innovate.",
};

const GetStarted = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    option: "",
    message: "",
  });

  const [selectedConsultingOptions, setSelectedConsultingOptions] = useState(
    [],
  );
  const [selectedTechOptions, setSelectedTechOptions] = useState([]);

  const [selectedConsultingDescription, setSelectedConsultingDescription] =
    useState("");
  const [selectedTechDescription, setSelectedTechDescription] = useState("");
  const [descriptionAnimationCompleted, setDescriptionAnimationCompleted] =
    useState(false);

  const [formError, setFormError] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [showTechInfo, setShowTechInfo] = useState(false);
  const [showConsultingInfo, setShowConsultingInfo] = useState(false);

  const [optionsSelected, setOptionsSelected] = useState(false);
  const [popupActive, setPopupActive] = useState(false);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormError(false);

    // Check if the value is "tech" or "consulting" and update the respective flags
    if (value === "tech") {
      setShowTechInfo(true);
      setShowConsultingInfo(false);
    } else if (value === "consulting") {
      setShowTechInfo(false);
      setShowConsultingInfo(true);

      // Update consulting description
      const consultingDescription =
        selectedConsultingOptions.length > 0
          ? consultingDescriptions[selectedConsultingOptions[0]]
          : "";
      setSelectedConsultingDescription(consultingDescription);
    } else {
      // For any other value, keep the current visibility of descriptions
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setFormError("Please enter a valid email address.");
      return;
    }

    setIsSending(true);

    const dataToSend = {
      ...formData,
      selectedConsultingOptions,
      selectedTechOptions,
    };

    const proxyUrl = "https://soel-website.nassifchedrawi1.repl.co";
    const apiUrl = `${proxyUrl}/submitForm`;

    axios
      .post(apiUrl, dataToSend)
      .then((response) => {
        console.log("Form data sent successfully:", response.data);
        setIsSending(false);
        setIsSent(true);
        setSubmissionStatus("success");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setIsSending(false);
        setSubmissionStatus("error");
      });
  };

  const handleConsultingOptionClick = (option) => {
    setSelectedConsultingOptions((prevOptions) => {
      const newOptions = prevOptions.includes(option)
        ? prevOptions.filter((prevOption) => prevOption !== option)
        : [...prevOptions, option];

      // Update consulting descriptions based on selected options
      const descriptions = newOptions.map((opt) => consultingDescriptions[opt]);
      setSelectedConsultingDescription(descriptions.join("\n"));

      return newOptions;
    });
    setOptionsSelected(true);
  };

  const handleTechOptionClick = (option) => {
    setSelectedTechOptions((prevOptions) => {
      const newOptions = prevOptions.includes(option)
        ? prevOptions.filter((prevOption) => prevOption !== option)
        : [...prevOptions, option];

      // Update tech descriptions based on selected options
      const descriptions = newOptions.map((opt) => techDescriptions[opt]);
      setSelectedTechDescription(descriptions.join("\n"));

      return newOptions;
    });
    setOptionsSelected(true);
  };

  const containerClassName = optionsSelected
    ? "contact-container-gs options-selected"
    : popupActive
    ? "contact-container-gs popup-active"
    : "contact-container-gs split-layout"; // Updated class name for split layout

  // Function to dispatch "userscrolledup2" event
  const dispatchUserScrolledUp2 = () => {
    const event = new Event("userscrolledup2");
    window.dispatchEvent(event);
  };

  // Event listener for scroll actions (wheel, key up/down, touch scroll)
  useEffect(() => {
    const handleScroll = (e) => {
      const scrollDirection = e.deltaY > 0 ? 1 : -1;

      if (scrollDirection === -1) {
        // Dispatch a custom event named "userscrolledup2" to notify Home.js when the user scrolls up
        dispatchUserScrolledUp2();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp") {
        // Dispatch a custom event named "userscrolledup2" to notify Home.js when the user scrolls up/down with arrow keys
        dispatchUserScrolledUp2();
      }
    };

    const handleTouchStart = (e) => {
      const touchStartY = e.touches[0].clientY;

      const handleTouchMove = (moveEvent) => {
        const touchEndY = moveEvent.changedTouches[0].clientY;
        const scrollDirection = touchEndY > touchStartY ? 1 : -1;

        if (scrollDirection === -1) {
          // Dispatch a custom event named "userscrolledup2" to notify Home.js when the user scrolls up
          dispatchUserScrolledUp2();
        }

        // Remove the touch move event listener
        window.removeEventListener("touchmove", handleTouchMove);
      };

      window.addEventListener("touchmove", handleTouchMove);
    };

    window.addEventListener("wheel", handleScroll);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("touchstart", handleTouchStart);

    // Cleanup by removing the event listeners when the component unmounts
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <div className={containerClassName}>
      <div className="left-side">
        <div className="information-container-gs">
          {(!optionsSelected || showConsultingInfo || showTechInfo) && (
            <div className="info-box-gs">
              <h3>About Our Services</h3>
              <div className="description-container-gs">
                <div className="description-container-gs">
                  {showConsultingInfo &&
                    selectedConsultingOptions.map((option) => (
                      <div key={option} className="description-gs animate-gs">
                        {consultingDescriptions[option]}
                      </div>
                    ))}
                  {showTechInfo &&
                    selectedTechOptions.map((option) => (
                      <div key={option} className="description-gs animate-gs">
                        {techDescriptions[option]}
                      </div>
                    ))}
                </div>

                {!showConsultingInfo && !showTechInfo && (
                  <div className="description1-gs animate-gs">
                    Don't know where to start? Send us an inquiry, and we will
                    get in touch ASAP!
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="right-side"
        style={{ backgroundImage: `url(${videoBackground})` }}
      >
        <video autoPlay loop muted className="video-background-D">
          <source src={videoBackground} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="form-container-gs">
          <h2>Get Started</h2>
          {submissionStatus === "success" ? (
            <div className="submission-confirmation-gs">
              Message sent successfully!
            </div>
          ) : (
            formError && <div className="error-message-gs">{formError}</div>
          )}
          {submissionStatus === "success" ? (
            <div className="submitted-info-gs">
              <h3>Submitted Information:</h3>
              <p>Name: {formData.name}</p>
              <p>Email: {formData.email}</p>
              <p>Option: {formData.option}</p>
              {formData.option === "consulting" && (
                <p>
                  Consulting Options: {selectedConsultingOptions.join(", ")}
                </p>
              )}
              {formData.option === "tech" && (
                <p>Technology Options: {selectedTechOptions.join(", ")}</p>
              )}
              <p>Message: {formData.message}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-field-gs">
                <label htmlFor="name" />
                <input
                  id="name"
                  type="text"
                  name="name"
                  required
                  onChange={handleInputChange}
                  placeholder="Enter your name"
                />
              </div>
              <div className="input-field-gs">
                <label htmlFor="email" />
                <input
                  id="email"
                  type="email"
                  name="email"
                  required
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="input-field-gs">
                <label htmlFor="option" />
                <select
                  id="option"
                  name="option"
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Select a service line</option>
                  <option value="consulting">Consulting</option>
                  <option value="tech">Tech</option>
                </select>
              </div>
              {formData.option === "consulting" && (
                <div className="options-container-gs">
                  <p className="options-label-gs">
                    Consulting Options:
                    <span
                      className="info-button-gs"
                      title="Select an option and see what to expect"
                    >
                      i
                    </span>
                  </p>
                  <div className="options-boxes-gs">
                    <div
                      className={`option-box-gs ${
                        selectedConsultingOptions.includes("El Raise") &&
                        "selected-gs"
                      }`}
                      onClick={() => handleConsultingOptionClick("El Raise")}
                    >
                      El Raise
                    </div>
                    <div
                      className={`option-box-gs ${
                        selectedConsultingOptions.includes("El Deck") &&
                        "selected-gs"
                      }`}
                      onClick={() => handleConsultingOptionClick("El Deck")}
                    >
                      El Deck
                    </div>
                    <div
                      className={`option-box-gs ${
                        selectedConsultingOptions.includes("El Strategy") &&
                        "selected-gs"
                      }`}
                      onClick={() => handleConsultingOptionClick("El Strategy")}
                    >
                      El Strategy
                    </div>
                  </div>
                </div>
              )}

              {formData.option === "tech" && (
                <div className="options-container-gs">
                  <p className="options-label-gs">
                    Technology Options:
                    <span
                      className="info-button-gs"
                      title="Select an option to see what to expect"
                    >
                      i
                    </span>
                  </p>
                  <div className="options-boxes-gs">
                    <div
                      className={`option-box-gs ${
                        selectedTechOptions.includes(
                          "El Tech Infrastructure",
                        ) && "selected-gs"
                      }`}
                      onClick={() =>
                        handleTechOptionClick("El Tech Infrastructure")
                      }
                    >
                      El Tech Infrastructure
                    </div>
                    <div
                      className={`option-box-gs ${
                        selectedTechOptions.includes("El UX/UI") &&
                        "selected-gs"
                      }`}
                      onClick={() => handleTechOptionClick("El UX/UI")}
                    >
                      El UX/UI
                    </div>
                    <div
                      className={`option-box-gs ${
                        selectedTechOptions.includes("El Web3") && "selected-gs"
                      }`}
                      onClick={() => handleTechOptionClick("El Web3")}
                    >
                      El Web3
                    </div>
                  </div>
                </div>
              )}
              <div className="input-field-gs">
                <label htmlFor="message" />
                <textarea
                  id="message"
                  name="message"
                  onChange={handleInputChange}
                  placeholder="Your message (optional)"
                />
              </div>

              <button
                type="submit"
                className={`send-button ${isSending ? "sending" : ""}`}
                disabled={isSending || isSent}
              >
                {isSending ? "Sending..." : isSent ? "Sent ✔" : "Send Message"}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
