import React, { useState, useEffect, useRef } from "react";
import MobileIntro from "./MobileIntro";
import MobileHamburger from "./MobileHamburger";
import logo from "../SOEL-LOGO2.png";
import MobileServices from "./MobileServices";
import MobileGetStarted from "./MobileGetStarted";
import "./MobileHome.css";

const MobileHome = () => {
  useEffect(() => {
    // Create a new script element
    const script = document.createElement("script");

    // Set the script type
    script.type = "text/javascript";

    // Set the script content (your provided script)
    script.innerHTML = `
      window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'f322439d3a60b959aab5ea0ff6b1484b697adf97', { region: 'eu' });
    `;

    // Append the script element to the head
    document.head.appendChild(script);

    // Clean up by removing the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const introRef = useRef(null);
  const servicesRef = useRef(null);
  const getStartedRef = useRef(null);

  const [activeSection, setActiveSection] = useState(introRef);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [reachedLastSentence, setReachedLastSentence] = useState(false);

  const [selected, setSelected] = useState(0);
  const [isInSectionView, setIsInSectionView] = useState(Array(3).fill(false));
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const [isNavBarVisible, setIsNavBarVisible] = useState(false);

  const navItems = [
    { id: "intro", name: "Introduction" },
    { id: "services", name: "Our Services" },
    { id: "start", name: "Get Started" },
  ];

  const sectionRefs = [introRef, servicesRef, getStartedRef];

  const handleClick = (index) => {
    setIsUserScrolling(true);
    if (index !== selected) {
      setSelected(index);
      const newActiveSection = sectionRefs[index];
      setActiveSection(newActiveSection);
      scrollToSection(index);

      console.log(
        `Changed active section to: ${
          newActiveSection === introRef
            ? "Introduction"
            : newActiveSection === servicesRef
            ? "Our Services"
            : "Get Started"
        }`,
      );
      console.log(`Triggered by clicking on: ${navItems[index].name}`);
    }
  };

  const scrollToSection = (index) => {
    const selectedSection = sectionRefs[index]?.current;
    if (selectedSection) {
      selectedSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleNavBarVisibility = () => {
    setIsNavBarVisible(!isNavBarVisible);
  };

  useEffect(() => {
    let isAnimating = false;

    const isInView = (element) => {
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return rect.top <= window.innerHeight && rect.bottom >= 0;
    };

    const handleScroll = () => {
      if (!isAnimating) {
        window.requestAnimationFrame(() => {
          if (isUserScrolling) {
            setIsUserScrolling(false);
          } else {
            // This array will hold boolean values indicating whether each section is in view.
            const inViewIndices = sectionRefs.map((ref) =>
              isInView(ref.current),
            );

            // Find the index of the section currently in view.
            const newActiveIndex = inViewIndices.lastIndexOf(true);

            // If we find a section that is in view
            if (newActiveIndex !== -1) {
              // Update the selected navbar item.
              setSelected(newActiveIndex);

              // Update the active section.
              const newActiveSection = sectionRefs[newActiveIndex];
              setActiveSection(newActiveSection);
            }
          }
          isAnimating = false;
        });
        isAnimating = true;
      }
    };

    const handleLastSentenceReached = () => {
      setActiveSection(servicesRef);
      setSelected(1);
      console.log("Last sentence reached triggered by event");
    };

    const handleGetStartedPressed = () => {
      setActiveSection(getStartedRef);
      setSelected(2);
      console.log("Get started pressed triggered by event");
    };

    window.addEventListener("lastSentenceReached", handleLastSentenceReached);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("getStartedPressed", handleGetStartedPressed);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener(
        "lastSentenceReached",
        handleLastSentenceReached,
      );
      window.removeEventListener("getStartedPressed", handleGetStartedPressed);
    };
  }, [isUserScrolling, sectionRefs, isInSectionView]);

  // New useEffect to update selected based on activeSection
  useEffect(() => {
    // Find the index of the active section in sectionRefs.
    const activeIndex = sectionRefs.findIndex((ref) => ref === activeSection);

    // Update the selected navigation item.
    setSelected(activeIndex);
  }, [activeSection]); // This useEffect depends on the activeSection

  const handleLastSentenceReached = (reached) => {
    setReachedLastSentence(reached);
    if (reached) {
      const event = new Event("scrollToServices");
      window.dispatchEvent(event);
    }
  };

  const closeNavbar = () => {
    setIsNavBarVisible(false);
  };

  useEffect(() => {
    const handleBodyClick = (e) => {
      if (!document.querySelector(".nav-container-m").contains(e.target)) {
        closeNavbar();
      }
    };

    if (isNavBarVisible) {
      document.body.addEventListener("click", handleBodyClick);
    }

    const handleScrollingUpInMainSection = () => {
      console.log("Scrolling up in the main section");
      setActiveSection(introRef);
    };

    const handleUserInTechAndScrollDown = () => {
      console.log("User in tech and scroll down");
      setActiveSection(getStartedRef);
    };

    const handleCustomEvent = (e) => {
      if (e.type === "swipeUpInMobileGetStarted") {
        console.log(
          "Swipe up detected in the child component (MobileGetStarted)",
        );
        setActiveSection(servicesRef);
      }
    };

    window.addEventListener(
      "scrollingUpInMainSection",
      handleScrollingUpInMainSection,
    );
    window.addEventListener(
      "userInTechAndScrollDown",
      handleUserInTechAndScrollDown,
    );
    document.addEventListener("swipeUpInMobileGetStarted", handleCustomEvent);

    const handleHamburgerClick = () => {
      toggleNavBarVisibility();
    };

    const hamburgerMenu = document.querySelector(".mobile-hamburger-m");
    if (hamburgerMenu) {
      hamburgerMenu.addEventListener("click", handleHamburgerClick);
    }

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
      window.removeEventListener(
        "scrollingUpInMainSection",
        handleScrollingUpInMainSection,
      );
      window.removeEventListener(
        "userInTechAndScrollDown",
        handleUserInTechAndScrollDown,
      );
      document.removeEventListener(
        "swipeUpInMobileGetStarted",
        handleCustomEvent,
      );

      if (hamburgerMenu) {
        hamburgerMenu.removeEventListener("click", handleHamburgerClick);
      }
    };
  }, []); //isNavBarVisible

  return (
    <div className="container-m">
      <MobileHamburger className="mobile-hamburger-m" />
      <div className="nav-container-m">
        <MobileHamburger className="mobile-hamburger-m" />
        <div className="logo-container-m" onClick={toggleNavBarVisibility}>
          <img
            src={logo}
            alt="SOEL Logo"
            className={`logo-m ${isNavBarVisible ? "clickable" : ""}`}
            id="logo-m"
          />
        </div>
        {isNavBarVisible && (
          <div className="nav-items-container-m">
            {navItems.map((item, index) => (
              <div
                key={item.id}
                className={`nav-item-m ${selected === index ? "active-m" : ""}`}
                onClick={() => handleClick(index)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {activeSection === introRef && (
        <div
          className={`section-m intro-m ${
            activeSection === introRef ? "active-m" : ""
          }`}
          ref={introRef}
        >
          <MobileIntro
            className="mobile-intro-m"
            scrollProgress={scrollProgress}
            onLastSentenceReached={handleLastSentenceReached}
          />
        </div>
      )}
      {activeSection === servicesRef && (
        <div
          className={`section-m our-services-m ${
            activeSection === servicesRef ? "active-m" : ""
          }`}
          ref={servicesRef}
        >
          <MobileServices className="mobile-services-m" />
        </div>
      )}
      {activeSection === getStartedRef && (
        <div
          className={`section-m get-started-m ${
            activeSection === getStartedRef ? "active-m" : ""
          }`}
          ref={getStartedRef}
        >
          <MobileGetStarted className="mobile-get-started-m" />
        </div>
      )}
    </div>
  );
};

export default MobileHome;
