// MobileServices.js
import React, { useState, useRef, useEffect } from "react";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import "./MobileServices.css";
import MobileTechD from "./MobileTechD"; // Adjust the import to your folder structure
import MobileConsD from "./MobileConsD";

gsap.registerPlugin(TextPlugin);

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const MobileServices = () => {
  const sentence = "So-EL POWERS FOUNDERS THROUGH TWO MAIN SERVICES";
  const [currentSection, setCurrentSection] = useState("sentenceRef");
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [lastChange, setLastChange] = useState(Date.now());
  const [switchValue, setSwitchValue] = useState(0);

  const handleSectionChange = () => {
    if (currentSection === "sentenceRef") {
      setCurrentSection("consulting");
    } else if (currentSection === "consulting") {
      setCurrentSection("technology");
    } else if (currentSection === "technology") {
      setCurrentSection("sentenceRef");
    }
  };

  const sectionRefs = {
    sentenceRef: useRef(null),
    consulting: useRef(null),
    technology: useRef(null),
  };

  const typingEffect = () => {
    gsap.set(sectionRefs.sentenceRef.current, { opacity: 0, text: "" });

    gsap.to(sectionRefs.sentenceRef.current, {
      duration: sentence.length / 10,
      text: sentence,
      opacity: 1,
      ease: "power1.out",
      delay: 0.5,
    });
  };

  useEffect(() => {
    if (currentSection === "sentenceRef") {
      typingEffect();
    }
  }, [currentSection]);

  const sections = Object.keys(sectionRefs); // Extracts ["sentenceRef", "consulting", "technology"]

  const handleEvent = debounce((direction) => {
    if (Date.now() - lastChange < 500) return;
    setLastChange(Date.now());

    const currentIndex = sections.indexOf(currentSection);
    let nextIndex = direction === "down" ? currentIndex + 1 : currentIndex - 1;

    if (nextIndex >= 0 && nextIndex < sections.length) {
      setCurrentSection(sections[nextIndex]);
    }

    if (currentSection === "sentenceRef" && direction === "up") {
      const event = new Event("scrollingUpInMainSection");
      window.dispatchEvent(event);
      console.log("Scrolling up in the main section");
    } else if (currentSection === "technology" && direction === "down") {
      const event = new Event("userInTechAndScrollDown");
      window.dispatchEvent(event);
      console.log("User in tech and scroll down");
    }
  }, 50);

  const handleWheelScroll = (e) => {
    const direction = e.deltaY > 0 ? "down" : "up";
    handleEvent(direction);
  };

  let initialTouchY = null; // Declare this outside the component or inside a useRef for persistence

  const handleTouchStart = (e) => {
    initialTouchY = e.touches[0].clientY; // Store the initial touch point
  };

  const handleTouchMove = (e) => {
    if (initialTouchY === null) return; // No initial touch point

    const currentTouchY = e.touches[0].clientY;
    const threshold = 50; // Set the threshold to a suitable value

    if (Math.abs(currentTouchY - initialTouchY) < threshold) {
      return; // Movement is within the threshold, so don't trigger a scroll action
    }

    const direction = currentTouchY < initialTouchY ? "down" : "up";
    handleEvent(direction);

    initialTouchY = null; // Reset initial touch point
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") handleEvent("down");
    if (e.key === "ArrowUp") handleEvent("up");
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheelScroll);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("wheel", handleWheelScroll);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSection, lastChange]);

  const handleGetStarted = () => {
    console.log("Get Started button pressed in MobileTechD");
    setSwitchValue(1);

    // Dispatch a custom event to notify MobileHome
    const event = new Event("getStartedPressed");
    window.dispatchEvent(event);
  };
  useEffect(() => {
    if (sectionRefs[currentSection].current) {
      gsap.to(sectionRefs[currentSection].current, {
        y: 0,
        duration: 0.5,
        autoAlpha: 1,
      });

      Object.keys(sectionRefs)
        .filter((key) => key !== currentSection)
        .forEach((key) => {
          if (sectionRefs[key].current) {
            gsap.to(sectionRefs[key].current, {
              y: "100%",
              duration: 0.5,
              autoAlpha: 0,
            });
          }
        });
    }
  }, [currentSection]);

  return (
    <div className="services-container-mobile">
      <div
        ref={sectionRefs.sentenceRef}
        className={`service main-section-mobile ${
          currentSection === "sentenceRef" ? "active sentence-ref" : ""
        }`}
      ></div>

      <div
        ref={sectionRefs.consulting}
        className={`sub-section-mobile left-mobile ${
          currentSection === "consulting" ? "active" : ""
        }`}
        onMouseEnter={() => {
          setDescriptionVisible(true);
        }}
        onMouseLeave={() => {
          setDescriptionVisible(false);
        }}
      >
        <div className="title-box-mobile">
          <h1 className="consulting-heading-mobile">CONSULTING</h1>
        </div>
        {currentSection === "consulting" && (
          <MobileConsD onGetStarted={handleGetStarted} />
        )}
      </div>

      <div
        ref={sectionRefs.technology}
        className={`sub-section-mobile right-mobile ${
          currentSection === "technology" ? "active" : ""
        }`}
      >
        <div className="title-box-mobile">
          <h1 className="technology-heading-mobile">TECHNOLOGY</h1>
          <div className="mobile-tech-container">
            {currentSection === "technology" && (
              <MobileTechD onGetStarted={handleGetStarted} />
            )}
          </div>
        </div>
      </div>

      <div className="mouse-field-container">
        <div className="mouse-field">
          <div className="mouse"></div>
        </div>
        <div className="scroll-down-text">SCROLL DOWN</div>
      </div>
    </div>
  );
};

export default MobileServices;
