import React, { useState, useRef, useEffect } from "react";
import "./MobileTechD.css";
import gsap from "gsap";
import {
  FaPencilAlt, // Placeholder for EL Deck
  FaLaptop, // Placeholder for EL Infrastructure
  FaChartLine, // Placeholder for EL Strategy
  FaCogs, // Placeholder for EL Tech Continuity
  FaBox, // Placeholder for EL Tech Package
  FaCube, // Placeholder for EL Web3-build
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

const MobileConsD = ({ onGetStarted }) => {
  const progressRef = useRef(null);

  const consultingServices = [
    {
      title: "EL Deck",
      description:
        "We create and design your pitch deck using your existing material, within a 7 business day timeframe. Up to 20 slides with 2 rounds of revisions.",
      icon: <FaPencilAlt />,
    },
    {
      title: "EL Raise",
      description:
        "We create comprehensive data rooms tailored to your startup's fundraising needs. This incorporates a structured approach that includes various critical documents.",
      icon: <FaLaptop />,
    },
    {
      title: "EL Strategy",
      description:
        "Our team provides a detailed growth and exit strategy, including key fundraising rounds, capital requirements, and essential legal insights.",
      icon: <FaChartLine />,
    },
    // Add other consulting services here
  ];

  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

  const animateServiceChange = () => {
    const timeline = gsap.timeline();
    timeline.to(".service-title svg", { y: -10, duration: 0.2 });
    timeline.from(
      ".service-title h2",
      { x: -20, opacity: 0, duration: 0.4 },
      "-=0.2",
    );
  };

  const changeService = (newIndex) => {
    gsap.to(".service-content", { opacity: 0, duration: 0.3 });
    animateServiceChange();
    animateBackgroundColorChange();
    setTimeout(() => {
      setCurrentServiceIndex(newIndex);
      gsap.to(".service-content", { opacity: 1, duration: 0.3 });
    }, 300);
  };

  const nextService = () => {
    changeService((prevIndex) => (prevIndex + 1) % consultingServices.length);
  };

  const prevService = () => {
    changeService(
      (prevIndex) =>
        (prevIndex - 1 + consultingServices.length) % consultingServices.length,
    );
  };

  const animateBackgroundColorChange = () => {
    const colors = [
      "#3f49e8", // Blue
      "#f053bb", // Pink
      "#2ecc71", // Green
      "#e67e22", // Orange
      "#9b59b6", // Purple
      "#3498db", // Light Blue
    ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    gsap.to(".technology-details-popup", {
      backgroundColor: randomColor,
      duration: 0.5,
      ease: "power1.inOut",
    });
  };

  const updateProgress = () => {
    const progress =
      ((currentServiceIndex + 1) / consultingServices.length) * 100;
    gsap.to(".progress-bar-fill", {
      width: `${progress}%`,
      duration: 0.5,
      ease: "power3.out",
    });
  };

  useEffect(updateProgress, [currentServiceIndex]);

  // Step 2: Call the onGetStarted function when the button is pressed
  const handleGetStarted = () => {
    onGetStarted(); // Call the provided onGetStarted function
  };

  return (
    <div className="consulting-details-popup-mob">
      <div className="service-content-mob">
        <div className="service-title-container-mob">
          {consultingServices[currentServiceIndex].icon}
          <h2 className="service-title-mob">
            {consultingServices[currentServiceIndex].title}
          </h2>
        </div>
        <p className="service-description-mob">
          {consultingServices[currentServiceIndex].description}
        </p>
      </div>
      <div className="carousel-nav-mob">
        <button className="carousel-button-mob" onClick={prevService}>
          <FaChevronLeft />
        </button>

        <button className="carousel-button-mob" onClick={nextService}>
          <FaChevronRight />
        </button>
      </div>
      <div className="carousel-dots-mob">
        {" "}
        {consultingServices.map((_, index) => (
          <button
            key={index}
            className={`carousel-dot-mob ${
              index === currentServiceIndex ? "active-mob" : ""
            }`}
            onClick={() => changeService(index)}
          />
        ))}
      </div>
      {/* Step 3: Call the handleGetStarted function when the button is pressed */}
      <button className="get-started-button-mob" onClick={handleGetStarted}>
        Get Started
      </button>
    </div>
  );
};

export default MobileConsD;
