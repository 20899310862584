import React, { forwardRef, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./MobileHamburgerPage.css";

const MobileHamburgerPage = forwardRef(({ onClose }, ref) => {
  const optionsRef = useRef([]);
  optionsRef.current = [0, 1, 2].map(
    (_, i) => optionsRef.current[i] ?? useRef(),
  );

  useEffect(() => {
    optionsRef.current.forEach((ref) => {
      gsap.set(ref.current, { autoAlpha: 0 });
      gsap.fromTo(
        ref.current,
        { y: "-=15" },
        { autoAlpha: 1, y: "+=15", stagger: 0.1 },
      );
    });
  }, []);

  const handleMouseEnter = (index) => {
    gsap.to(optionsRef.current[index].current, {
      duration: 0.2,
      "::after": { width: "100%" },
    });
  };

  const handleMouseLeave = (index) => {
    gsap.to(optionsRef.current[index].current, {
      duration: 0.2,
      "::after": { width: "0%" },
    });
  };

  // Function to handle the click on the "Free YC Safe Calculator" button
  const handleCalculatorClick = () => {
    window.open("http://safecalculator.so-el.xyz/", "_blank");
  };

  // Function to handle the click on the "Schedule a meeting" button
  const handleMeetingClick = () => {
    window.open("https://calendly.com/nassif-215/30min", "_blank");
  };

  return (
    <div className="hamburger-page" ref={ref}>
      <div className="options-container">
        {[
          "Blog (Coming Soon)",
          "Schedule a meeting",
          "Free YC Safe Calculator",
        ].map((option, i) => (
          <h2
            key={option}
            ref={optionsRef.current[i]}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={() => handleMouseLeave(i)}
            onClick={
              option === "Free YC Safe Calculator"
                ? handleCalculatorClick
                : option === "Schedule a meeting"
                ? handleMeetingClick
                : null
            }
            className="option"
          >
            {option}
          </h2>
        ))}
      </div>
    </div>
  );
});

export default MobileHamburgerPage;
