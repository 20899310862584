import React, { useEffect, useState } from "react";
import gsap from "gsap";
import "./IntroContent.css";
import video from "./Media/BGVid-02.mp4"; // Make sure to provide the correct path to your video file


const paragraphs = [
  {
    text: "Welcome to our community of experienced founders! We offer customized solutions to help you overcome the various challenges of startup life, such as securing funding and scaling your business.",
  },
  {
    text: "Our goal is simple: to help your startup grow. We do this by focusing on two key areas that are critical to your success: how your business operates and the technology you use.",
  },
  {
    text: "We are experts in four essential areas:",
    items: [
      {
        label: "Fundraising: Secure the capital you need to grow",
        logo: "FundraiseLogo.png",
      },
      {
        label:
          "Business Operations: Streamline how your business works for better efficiency",
        logo: "Crowdfunding.png",
      },
      {
        label:
          "Web 2.0 Software Development: Build or improve your digital platforms",
        logo: "SoftwareDev.png",
      },
      {
        label:
          "Decentralized Web 3.0 Technologies: Embrace the future of the digital world",
        logo: "Web3Logo.png",
      },
    ],
  },
  {
    text: "Your vision propels you forward, and our expertise can take you to new heights. Partner with us to gain access to proven strategies and cutting-edge technologies that have transformed startups into market leaders.",
  },
];

const IntroContent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [canScroll, setCanScroll] = useState(true);
  const emitTTNSEvent = () => {
    const event = new Event("TTNS");
    window.dispatchEvent(event);
  };

  const animateToIndex = (fromIndex, toIndex, direction) => {
    const timeline = gsap.timeline({
      onComplete: () => {
        setCurrentIndex(toIndex);
        setCanScroll(true);
      },
    });

    const yDirection = direction === "up" ? "100%" : "-100%";

    timeline.to(`#sentence-${fromIndex}`, {
      duration: 0.4,
      y: yDirection,
      opacity: 0,
    });

    timeline.fromTo(
      `#sentence-${toIndex}`,
      {
        y: direction === "up" ? "-100%" : "100%",
        opacity: 0,
      },
      {
        duration: 0.4,
        y: "0%",
        opacity: 1,
      },
    );
  };

  const handleEvent = (direction) => {
    if (!canScroll) return;

    let nextIndex = direction === "down" ? currentIndex + 1 : currentIndex - 1;

    if (nextIndex >= 0 && nextIndex < paragraphs.length) {
      // Check if the next paragraph contains the target text
      const nextParagraph = paragraphs[nextIndex];
      if (
        nextParagraph.text ===
        "Your vision propels you forward, and our expertise can take you to new heights. Partner with us to gain access to proven strategies and cutting-edge technologies that have transformed startups into market leaders."
      ) {
        console.log("last sentence reached");
      }

      setCanScroll(false);
      animateToIndex(currentIndex, nextIndex, direction);
    }
    if (nextIndex === paragraphs.length && direction === "down") {
      // Last sentence is reached and user scrolls down
      console.log("TTNS");
      emitTTNSEvent(); // Emit the TTNS event
    }
  };
  function generateSentenceClassName(index) {
    return `sentence-${index}`;
  }

  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === "ArrowDown") {
        handleEvent("down");
      } else if (e.key === "ArrowUp") {
        handleEvent("up");
      }
    };

    const handleScroll = (e) => {
      const direction = e.deltaY > 0 ? "down" : "up";
      handleEvent(direction);
    };

    document.addEventListener("keydown", handleKeydown);
    window.addEventListener("wheel", handleScroll);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("wheel", handleScroll);
    };
  }, [currentIndex, canScroll]);

  return (
    <div className="intro-content-container">
      <video autoPlay loop muted id="myVideo">
        <source src={video} type="video/mp4" />
      </video>

      {paragraphs.map(({ text, items }, index) => (
        <div
          id={`sentence-${index}`}
          className={`sentence ${index === currentIndex ? "visible" : ""}`}
          key={index}
        >
          {text}
          {items && (
            <div className="specialized-area-container">
              {items.map((item, i) => (
                <div className="specialized-area" key={i}>
                  <img
                    src={require(`./Media/${item.logo}`)}
                    alt={`${item.label} logo`}
                  />
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <div className="mouse-field-container">
        <div className="mouse-field">
          <div className="mouse"></div>
        </div>
        <div className="scroll-down-text">SCROLL DOWN</div>
      </div>
    </div>
  );
};

export default IntroContent;
